import { Module, ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { IRootState } from "@/store";
import api from "./apiCalls";
import { ActiveModel, IModel } from "@/shared/model";
import utils from "@/shared/utils";
import appStore from "@/store";

export const state: IModelState = {
  activeTeamModels: undefined,
  activeModel: undefined,
  showModelInsightInfo: false,
  lastRecentTeamModelsFetch: 0,
  lastTeamIdFetch: undefined,
};
export interface IModelState {
  activeTeamModels?: IModel[];
  activeModel?: IModel;
  showModelInsightInfo: boolean;
  lastRecentTeamModelsFetch: number;
  lastTeamIdFetch?: string;
}

const actions: ActionTree<IModelState, IRootState> = {
  deleteModel({}: any, modelId: string): Promise<object> {
    return api.deleteModel(modelId);
  },
  setActiveModel(store: ActionContext<IModelState, any>, model: IModel): void {
    store.commit("ActiveModel", model);
    const cookies = $cookies;
    const user = appStore.getters["login/getUser"];
    const activeTeam = appStore.getters["team/activeTeam"];
    if (cookies) {
      if (model && model.hasOwnProperty("_id")) {
        cookies.set("modelId", model._id);
        if (activeTeam && activeTeam.hasOwnProperty("account_id")) {
          cookies.set("teamid", activeTeam.account_id);
          try {
            utils.updateBeamer(user, activeTeam, model);
          } catch (e: any) {
            console.error("Error updating Beamer Parameters " + e);
          }
        }
      } else {
        cookies.set("modelId", null);
      }
    }
  },
  setActiveModelSilent(store: ActionContext<IModelState, any>, model: IModel): void {
    store.commit("ActiveModelSilent", model);
    const cookies = $cookies;
    const user = appStore.getters["login/getUser"];
    const activeTeam = appStore.getters["team/activeTeam"];
    if (cookies) {
      if (model && model.hasOwnProperty("_id")) {
        cookies.set("modelId", model._id);
        if (activeTeam && activeTeam.hasOwnProperty("account_id")) {
          cookies.set("teamid", activeTeam.account_id);
          try {
            utils.updateBeamer(user, activeTeam, model);
          } catch (e: any) {
            console.error("Error updating Beamer Parameters " + e);
          }
        }
      } else {
        cookies.set("modelId", null);
      }
    }
  },
  setTeamModels(store: ActionContext<IModelState, any>, models: IModel[]): void {
    store.commit("TeamModels", models);
  },
  getTeamModels(store: ActionContext<IModelState, any>): Promise<IModel[]> {
    return new Promise(async (resolve, reject) => {
      const activeTeam = appStore.getters["team/activeTeam"];
      const activeTeamId = activeTeam?.account_id;
      const currentTime = new Date().getTime();
      const fetchDiffTime = 5000;
      // Do not fetch again if last call was made less than 5sec before
      if (store.state.lastTeamIdFetch === activeTeamId && store.state.activeTeamModels && currentTime - store.state.lastRecentTeamModelsFetch < fetchDiffTime) {
        resolve(store.state.activeTeamModels);
        return;
      }
      store.commit("LastRecentTeamModelsFetch", currentTime);
      store.commit("LastTeamIdFetch", activeTeamId);
      const successHandler: (data: any) => void = (data: any) => {
        const models: IModel[] = data as IModel[];
        store.commit("TeamModels", models);
        resolve(models);
      };
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("fetching team models failed", error);
        reject(error);
      };
      api.getTeamModels(activeTeamId).then(successHandler).catch(errorHandler);
    });
  },
  getModelsForTeam(store: ActionContext<IModelState, any>, teamId: string): Promise<IModel[]> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        const models: IModel[] = data as IModel[];
        store.commit("TeamModels", models);
        resolve(models);
      };
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("fetching team models failed", error);
        reject(error);
      };
      api.getTeamModels(teamId).then(successHandler).catch(errorHandler);
    });
  },
};

const mutations: MutationTree<IModelState> = {
  TeamModels(state: IModelState, models: IModel[]): void {
    state.activeTeamModels = models;
  },
  ActiveModel(state: IModelState, model: IModel): void {
    state.activeModel = model;
  },
  ActiveModelSilent(state: IModelState, model: IModel): void {
    state.activeModel = model;
  },
  ShowModelInsightInfo(state: IModelState, show: boolean): void {
    state.showModelInsightInfo = show;
  },
  LastRecentTeamModelsFetch(state: IModelState, value: number): void {
    state.lastRecentTeamModelsFetch = value;
  },
  LastTeamIdFetch(state: IModelState, value: string): void {
    state.lastTeamIdFetch = value;
  },
};

const getters: GetterTree<IModelState, IRootState> = {
  getActiveModel(state: IModelState): ActiveModel | null {
    const { activeModel } = state;
    if (!activeModel) {
      return null;
    }
    let m: ActiveModel | null;
    try {
      m = new ActiveModel(activeModel);
    } catch (err) {
      console.error("not Model:", activeModel);
      m = null;
    }
    return m;
  },
  activeModelId(state: IModelState): string | null {
    const activeModel: IModel | undefined = state.activeModel;
    if (!activeModel || !activeModel._id) {
      return null;
    }
    return activeModel._id;
  },
  getActiveTeamModels(state: IModelState): IModel[] {
    const activeModels: IModel[] = state.activeTeamModels ?? [];

    return activeModels;
  },
  activeModel(state: IModelState): IModel | null {
    const { activeModel } = state;
    if (!activeModel) {
      return null;
    }
    return activeModel;
  },
  activeModelStateFinal(state: IModelState): boolean {
    const activeModel: IModel | undefined = state.activeModel;
    if (!activeModel) {
      return false;
    } else {
      const model_state: string | undefined = activeModel.model_state;
      if (model_state === "final") {
        return true;
      } else {
        return false;
      }
    }
  },
};

const namespaced: boolean = true;

export const modelStore: Module<IModelState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
